import BaseService from '../BaseService'
import {django, dvgr} from '@/config/api'

class Auth extends BaseService {
  static register (data) {
    const url = `${dvgr.api}/login/register`

    return this.sendData(url, data)
  }

  static login (data) {
    const url = `${dvgr.api}/login/authenticate`

    return this.sendData(url, data)
  }

  // static login (data) {
  //   const url = `${django.api}/login/`
  //   return this.sendData(url, data)
  // }

  static checkToken () {
    const url = `${dvgr.api}/login/userping`

    return this.getData(url)
  }

  static findAll () {
    const url = `${dvgr.api}/login/find-all`

    return this.getData(url)
  }
}

export default Auth
