<template>
  <b-navbar toggleable="sm"
            type="dark"
            style="overflow-x: hidden"
            class="p-0 b-nav justify-content-between">
    <div />
    <div>
      <b-navbar-brand class="title mb-0">
        Sistema de Rastreabilidade
      </b-navbar-brand>
      <b-navbar-brand>
        <img src="@/assets/img/logo.svg"
             alt="DVR"
             class="logo">
      </b-navbar-brand>
    </div>

    <b-row>
      <label for="button-sair"
             class="label mr-3"
             style="color: white"
             @click="''">
        Olá, {{ cliente }}
      </label>
      <button id="button-sair"
              class="btn btn-sm btn-dark mr-4"
              aria-label="Sair"
              name="button-sair"
              @click="logout()">
        Sair
        <b-icon-power />
      </button>
    </b-row>
  </b-navbar>
</template>


<script>
  import User from '@/services/user/User'
  export default {
    name: 'Navbar',
    data () {
      return{
        cliente: null
      }
    },
    mounted () {
      this.getNomeUsuario()
    },
    methods: {
      logout () {
        this.$store.dispatch('auth/logout').then(() => {
          this.$router.push({ name: 'Login' })
        })
      },
      async getNomeUsuario () {
        // try{
        //   loader({ active: true })
        const  res = await User.getNome()
        this.cliente = res.data.toUpperCase() + "      "
        // }catch ({ message, response }) {
        //   if (message && !response) {
        //     return displayMessage(`Erro!`, message, "error")
        //   }

        //   const {
        //     data: { status, message: mensagem },
        //   } = response

        //   let text = ""

        //   text += status ? `Status: ${status}` : ""
        //   text += mensagem ? `, ${mensagem}.` : ""

        //   displayMessage(`Erro!`, text, "error")
        // }finally{
        //   loader({ active: false })
        // }
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/_personalized.scss';
  .logo {
    width: 40px;
  }

  .title {
    font-size: 14pt;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  .b-nav {
    background-color: $dark-red !important;
  }

  .btn-menu:hover {
    cursor: pointer;
  }

  .logo {
    fill: black !important;
  }
</style>
