<template>
  <sidebar-menu :menu="menu"
                :collapsed="true" />


</template>
<script>
  import { SidebarMenu } from 'vue-sidebar-menu'
  import MENU from '@/constants/Menu.js'

  // outros
  import { mapGetters } from 'vuex'

  export default {
    name: 'Menu',
    components: {
      SidebarMenu
    },
    data () {
      return {
        menu: []
      }
    },
    computed: {
      ...mapGetters({
        userRoles: 'auth/getUserRoles'
      }),
    },
    watch: {
      userRoles () {
        this.filterMenu()
      }
    },
    beforeMount () {
      this.getMenu()
      this.filterMenu()
    },
    methods: {
      getMenu () {
        this.menu = MENU.map(item => {
          if (item.child) {
            item.child = [...item.child]
          }

          return {...item}
        })
      },
      filterMenu () {
        if (!this.userRoles || !this.userRoles.length) return

        this.menu = this.menu.filter((item) => {
          if (item.roles) {
            let autorizado = false

            item.roles.forEach(role => {
              if (this.userRoles.includes(role)) autorizado = true
            })

            if (autorizado) return item
          } else if (item.child) {

            item.child = item.child.filter((ch) => {
              if (ch.roles) {
                let autorizado = false

                ch.roles.forEach(role => {
                  if (this.userRoles.includes(role)) autorizado = true
                })

                if (autorizado) return ch
              } else {
                return ch
              }
            })

            return item
          } else {
            return item
          }
        })
      }
    }
  }
</script>
<style lang="scss">

</style>
