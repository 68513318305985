export default [
  {
    header: true,
    title: 'Menu',
    hiddenOnCollapse: true
  },
  {
    href: '/index',
    title: 'Tela Inicial',
    icon: 'fa fa-home'
  },
  {
    title: 'Cadastro',
    icon: 'fa fa-user-tag',
    child: [
      {
        href: '/cadastros/usuario',
        title: 'Usuarios',
        roles: ['ROLE_F_ADMIN']
      },
      {
        href: '/cadastros/estampador',
        title: 'Estampadores',
        roles: ['ROLE_F_ADMIN']
      },
      {
        href: '/cadastros/empresa-nf',
        title: 'Empresa - NFe',
        roles: ['ROLE_F_ADMIN']
      },
    ],
    roles: ['ROLE_F_ADMIN']
  },
  {
    title: 'Fabricante',
    icon: 'fas fa-archway',
    child: [
      {
        href: '/lotes-serpro/consulta',
        title: 'Solicitar Blanks'
      },
      {
        href: '/lotes-serpro/consulta-lotes',
        title: 'Consultar Blanks por lote'
      }
    ],
    roles: ['ROLE_F_ADMIN', 'ROLE_F_USER']
  },
  {
    title: 'Estoque Blanks',
    icon: 'fa fa-layer-group',
    child: [
      {
        href: '/lotes-blank/consultar',
        title: 'Consultar caixa estoque'
      },
      {
        href: '/lotes-blank/criar',
        title: 'Criar caixa'
      }
    ],
    roles: ['ROLE_F_ADMIN', 'ROLE_F_USER']
  },
  {
    title: 'Dados Blank',
    icon: 'fa fa-globe',
    child: [
      {
        href: '/blanks/consultar',
        title: 'Consultar blank'
      },
      {
        href: '/blanks/inativar',
        title: 'Inativar blanks - Fabricante',
        roles: ['ROLE_F_ADMIN']
      },
      {
        href: '/estampador/inativar-blank',
        title: 'Inativar blanks - Estampador',
        roles: ['ROLE_E_ADMIN']
      },
      {
        href: '/blanks/devolucao',
        title: 'Devolver placas ao fabricante',
        roles: ['ROLE_E_ADMIN']
      },
    ]
  },
  {
    title: 'Estampador',
    icon: 'fa fa-object-group',
    child: [
      {
        href: '/estampador/confirmar-recebimento-lote',
        title: 'Confirmar recebimento caixa'
      },
      {
        href: '/estampador/cadastros',
        title: 'Solicitantes & Proprietários'
      },
      {
        href: '/estampador/consultar',
        title: 'Consultar estampagem Blank'
      },
      {
        href: '/estampador/lotes-recebidos',
        title: 'Consultar Lotes Recebidos'
      },
    ],
    roles: ['ROLE_E_ADMIN']
  },
  {
    title: 'Estampagem',
    icon: 'fa fa-cubes',
    child: [
      {
        href: '/estampagem/cadastro-ae',
        title: 'Estampar Placas'
      },
      {
        href: '/estampagem/listar-aes',
        title: 'Listar AEs'
      }
    ],
    roles: ['ROLE_E_ADMIN']
  },
  {
    title: 'Relatórios',
    icon: 'fa fa-clipboard',
    child: [
      {
        href: '/relatorio/nfs',
        title: 'Notas Fiscais',
        roles: ['ROLE_E_ADMIN']
      },
      {
        href: '/relatorio/nfcs',
        title: 'Cupons Fiscais',
        roles: ['ROLE_E_ADMIN']
      },
      {
        href: '/relatorio/seriais',
        title: 'Seriais disponíveis',
        roles: ['ROLE_F_ADMIN']
      },
      {
        href: '/relatorio/estampagem',
        title: 'Estampagem',
        roles: ['ROLE_E_ADMIN']
      },
      // {
      //   href: '/relatorio/auditoria',
      //   title: 'Auditoria',
      //   roles: ['ROLE_E_ADMIN']
      // },
      {
        href: '#',
        title: 'Vendas',
        roles: ['ROLE_F_ADMIN']
      }

    ]
  },
  {
    title: 'Configurações',
    icon: 'fa fa-cog',
    child: [
      {
        href: '/estampador/certificado',
        title: 'Certificado'
      }
    ],
    roles: ['ROLE_E_ADMIN']
  },
]
