import Auth from "@/services/auth/Auth"

const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGOUT = 'LOGOUT'

export default {
  namespaced: true,
  state: {
    loaderStatus: {
      active: false,
      text: ''
    },
    logged: false,
    token: null,
    userRoles: []
  },
  getters: {
    getLoaderStatus: (state) => state.loaderStatus,
    getToken: (state) => state.token,
    getUserRoles: (state) => state.userRoles
  },
  mutations:
    {[LOGIN_SUCCESS] (state, dados) {
      state.logged = true
      state.token = dados.token
      state.userRoles = dados.roles
    },
    [LOGOUT] (state) {
      state.token = null
      state.logged = false
      state.userRoles = []
    },
    LOADER_ACTIVE (state, status) {
      state.loaderStatus = status
    },
    BODY_CLASS (state, status) {
      let el = document.body

      if (status) {
        el.classList.add('overflow-hidden')
      } else {
        el.classList.remove('overflow-hidden')
      }
    },
  },
  actions: {
    loader ({ commit }, status) {
      commit('BODY_CLASS', status.active)
      commit('LOADER_ACTIVE', status)
    },
    async check () {
      const res = await Auth.checkToken().then((response) => {
        return response.data
      }).catch(e => {
        console.error("Erro ao checar login.", e)
      })

      return res
    },
    async login ({ commit }, data) {
      commit(LOGIN_SUCCESS, data)
    },
    logout ({ commit }) {
      commit(LOGOUT)
    }
  }
}
