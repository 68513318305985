<template>
  <div id="app">
    <div v-if="!['/login', '/register'].includes($route.path)"
         class="sb-nav-fixed">
      <Navbar />

      <div id="layoutSidenav">
        <Menu />
      </div>
    </div>
    <router-view />
    <Loader />
  </div>
</template>
<script>
  // components
  import Navbar from '@/components/Navbar'
  import Menu from '@/components/Menu'
  import Loader from '@/components/Loader'

  // outros
  import { mapGetters } from 'vuex'

  export default {
    name: 'App',
    components: {
      Navbar,
      Menu,
      Loader
    },
    computed: {
      ...mapGetters({
        token: 'auth/getToken'
      }),
      logged () {
        return this.$store.state.auth.logged
      }
    },
    watch: {
      $route (to) {
        const { path } = to

        if (!this.logged && path !== '/login') {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push({ name: 'Login' })
          })
        }
      }
    },
  }
</script>
<style lang="scss">
  @import './scss/styles.scss';
</style>
