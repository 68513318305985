<template>
  <div aria-describedby="loader_content"
       class="loader d-flex justify-content-center align-items-center"
       :class="{ 'loader--active': getLoaderStatus.active }"
       role="alert"
       tabindex="-1">
    <p id="loader_content"
       class="loader_content"
       @click="closeLoader">
      {{ getLoaderStatus.text }}
    </p>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: "Loader",
    computed: {
      ...mapGetters({
        getLoaderStatus: 'auth/getLoaderStatus'
      })
    },
    methods: {
      closeLoader () {
        this.$store.dispatch('auth/loader', { active: false, text: '' }).then(()=> {})
        NProgress.done()
      }
    }
  }
</script>

<style lang="scss">
  @import '../scss/_utilities/exports';

  .loader {
    background-color: transparent;
    bottom: 0;
    height: 100vh;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    transition: transform ease-in-out .1s, background-color .1s ease-in-out;
    @include transform(scale(0));
    @include transformOrigin(center);
    z-index: -1;

    &_content {
      color: $color-light;
      font-size: pxToRem(18);
      font-weight: 600;
      line-height: pxToRem(24);
      margin-top: pxToRem(112);
    }

    &--active {
      background-color: rgba($color-dark, .75);
      @include transform(scale(1));
      z-index: 1100 !important;
    }

    &::before {
      @include animation(spinner, 2s, linear, 0s, infinite, null, null, null);
      border: pxToRem(4) solid $color-light;
      border-bottom-color: $color-blue;
      border-radius: 50%;
      content: "";
      height: pxToRem(60);
      left: 50%;
      opacity: inherit;
      position: absolute;
      top: 50%;
      @include transform(translate3d(-50%, -50%, 0));
      @include transformOrigin(center);
      width: pxToRem(60);
      will-change: transform;
    }
  }
</style>
