'use strict'

module.exports = {
  dvgr: {
    api: process.env.VUE_APP_DVGR_API
  },

  emplaca: {
    api: process.env.VUE_APP_EMPLACA_API
  },

  django: {
    api: process.env.VUE_APP_DVGR_API_DJANGO
  }
}