import BaseService from '../BaseService'
import { dvgr } from '@/config/api'

class User extends BaseService {
  static register (data) {
    const url = `${dvgr.api}/login/register`

    return this.sendData(url, data)
  }

  static login (data) {
    const url = `${dvgr.api}/login/authenticate`

    return this.sendData(url, data)
  }

  static findAll () {
    const url = `${dvgr.api}/login/find-all`

    return this.getData(url)
  }

  static getNome () {
    const url = `${dvgr.api}/version/cliente-logado`

    return this.getData(url)
  }
}

export default User
