import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Swal from 'sweetalert'
import { library,dom } from '@fortawesome/fontawesome-svg-core'
import { faHome, faLayerGroup, faClipboard, faUserTag, faFolder, faStream, faArchway, faObjectGroup, faCubes, faGlobe, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'
// const VueInputMask = require('vue-inputmask').default
import NProgress from "@/utils/progress"

import { CubeSpinner } from 'vue-spinners'


// import * as dotenv from 'dotenv'
// dotenv.config()

/* others */
import AuthInterceptor from '@/plugins/AuthInterceptor'


// Menu Lateral
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu)

//Font Awesome
dom.watch()
library.add(faHome)
library.add(faLayerGroup)
library.add(faCog)
library.add(faClipboard)
library.add(faUserTag)
library.add(faFolder)
library.add(faStream)
library.add(faArchway)
library.add(faObjectGroup)
library.add(faCubes)
library.add(faGlobe)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('cube', CubeSpinner)

window.loader = (obj) => {
  obj.active ? NProgress.start() : NProgress.done()
  store.dispatch("auth/loader", obj).then(res => res)
}

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(VueInputMask)
Vue.use(AuthInterceptor, { router })

Vue.config.productionTip = false

window.Swal = Swal
window.moment = moment

const displayMessage = (title, text, icon, buttons) => {
  const objSwal = {
    title: title || 'Sucesso!',
    text: text,
    buttons: buttons,
    dangerMode: icon === 'error' || buttons,
    icon: icon
  }

  return Swal(objSwal)
}

window.displayMessage = displayMessage

window.app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
