import axios from 'axios'
import store from "@/store"

class AuthInterceptor {
  constructor (options) {
    this.options = {
      ...options
    }

    this.configRequest()
    this.configResponse()
  }

  configRequest () {
    const onRequestSend = config => {
      const token = store.getters['auth/getToken']


      if (token) {
        if (!config.headers) {
          config.headers = {}
        }
        if (!config.headers.common) {
          config.headers.common = {}
        }
        config.headers.common['authorization'] = `Bearer ${token}`
      }

      config.timeout = 600000

      return config
    }


    axios.interceptors.request.use(onRequestSend)
  }

  configResponse () {
    const onSuccess = response => response

    const onError = error => {
      const path = '/dvrg-rastreabilidade-service/login/userping'

      if (error.response && error.response.status === 401 && error.response.data.path !== path) {
        store.dispatch('auth/check').then(async (resp) => {
          if (!resp) {
            store.dispatch('auth/logout').then(() => this.options.router.push({ path: '/login' }))
          }
        })
      }

      return Promise.reject(error)
    }

    axios.interceptors.response.use(onSuccess, onError)
  }

  static install (Vue, options) {
    /* eslint-disable no-new */
    new AuthInterceptor(options)
  }
}

export default AuthInterceptor
