import axios from "axios"

class BaseService {
  static sendData (url, data, config) {
    return axios.post(url, data, config)
  }

  static updateData (url, data, config) {
    return axios.put(url, data, config)
  }

  static destroyData (url, config) {
    return axios.delete(url, config)
  }

  static getData (url, config) {
    return axios.get(url, config)
  }

  static getHeaders (token) {
    return {
      Authorization: token
    }
  }
}

export default BaseService
